html,
body {
  margin: 0;
  padding: 0;
}

#devjams path {
  stroke-width: 5;
}

input[type="tel"] {
  background-color: #383b98;
  outline: none;
}

.Toastify__toast-container {
  z-index: 100000;
}

.w-md-editor-toolbar > ul > li > button[data-name="fullscreen"] {
  display: none;
}
