* {
  margin: 0;
}

.accordion-container {
  border-radius: 10px;
  background-color: #131111;
}

.faq .ac {
  margin: 0 !important;
  align-items: flex-start;
  background-color: #1e1e38 !important;
  border-bottom: 1px solid #1e1e38 !important;
}

.faq .ac-header {
  padding: 10px 25px !important;
  width: 100%;
  border: 1px solid #1e1e38 !important;
}

.faq div[id^="ac"] {
  border: 1px solid #2b2b5a !important;
}

.ac .ac-trigger {
  width: 100%;
  font-family: "Sora", sans-serif !important;
  font-weight: 600 !important;
}

.ac .ac-trigger:focus {
  outline: none !important;
}

.ac.blue .ac-trigger {
  color: #4285f4 !important;
}

.ac.green .ac-trigger {
  color: #34af53 !important;
}

.ac.yellow .ac-trigger {
  color: #fbbc05 !important;
}

.ac.red .ac-trigger {
  color: #ea4335 !important;
}

.ac-text {
  margin: 0 !important;
  padding: 0 !important;
}

.ac .ac-trigger::after {
  content: "▾" !important; /* Unicode character for "plus" sign (+) */
  float: right !important;
  margin-left: 5px !important;
}

.ac.is-active > .ac-header .ac-trigger::after {
  content: "▴" !important; /* Unicode character for "minus" sign (-) */
}

.ac .ac-panel {
  width: 100% !important;
  height: fit-content;
  display: flex;
  align-items: flex-start;
}

.ac.yellow .ac-panel {
  background-color: #faf9be !important;
}

.ac.green .ac-panel {
  background-color: #97f7af !important;
}

.ac.blue .ac-panel {
  background-color: #a3daff !important;
}

.ac.red .ac-panel {
  background-color: #f8a0b3 !important;
}

.ac .ac-panel .ac-text {
  font-family: "Sora", sans-serif !important;
  font-weight: 200 !important;
}

.text {
  float: left;
  display: block;
  box-sizing: border-box;
  margin: 20px 25px !important;
  padding: 10px;
}
