@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

.mlh-trust-badge {
  display: block;
  max-width: 100px;
  min-width: 60px;
  position: fixed;
  right: 10px;
  top: 0;
  width: 10%;
}

.content-container {
  @apply grid gap-4 py-3 lg:py-5 px-7 text-xs text-center sm:text-sm sm:text-left relative;
}

.grid-btn {
  @apply font-bold my-4 w-full py-3 rounded-md text-sm;
}

.animate-obj {
  @apply h-full absolute left-0 origin-center;
}

.formikLabel {
  @apply text-white mt-5 text-xs;
}

.formikInput {
  @apply px-3 py-2 text-sm w-full bg-jams_dark_purple text-white placeholder-gray-300 rounded-lg;
}

.navlink {
  @apply grid place-items-center whitespace-nowrap bg-jams_dark_blue px-3 py-4;
}

.err-msg {
  @apply text-red-500 w-full text-xs;
}

body,
html {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
}

.similar-teams {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
}

::-webkit-scrollbar {
  display: none;
}
